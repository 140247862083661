<template>
  <div class="login login-wrapper">
    <div class="card login-form">
      <div class="card-box">
        <div class="content">
          <div class="box">
            <img src="../assets/image/login.png" alt="" />
            <el-form
              :label-position="labelPosition"
              ref="loginForm"
              :model="loginForm"
              :rules="rules"
            >
              <el-form-item prop="admin_name">
                <el-input
                  placeholder="請輸入賬號"
                  v-model="loginForm.admin_name"
                  prop="name"
                  @keyup.enter.native="loginConfirm"
                >
                  <i
                    slot="prefix"
                    class="el-input__icon el-icon-user-solid"
                  ></i>
                </el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="請輸入6位登錄密碼"
                  v-model="loginForm.password"
                  show-password
                  @keyup.enter.native="loginConfirm"
                >
                  <i slot="prefix" class="el-input__icon el-icon-lock"></i>
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div class="button">
          <el-button
            @click="loginConfirm"
            type="primary"
            plain
            style="width: 100%"
            >登錄
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      labelPosition: "right",
      checked: "true",
      loginForm: {
        admin_name: "",
        password: "",
      },
      rules: {
        admin_name: [
          { required: true, message: "請輸入手機號", trigger: "blur" },
        ],
        password: [
          { required: true, message: "請輸入密碼", trigger: "change" },
        ],
      },
    };
  },
  created() {
    let token = sessionStorage.getItem("admintoken");
    if (token !== null) {
      this.$router.replace({
        path: "Home",
      });
    }
  },
  methods: {
    loginConfirm() {
      this.$refs.loginForm.validate(async (valid) => {
        if (valid) {
          this.$api.login(this.loginForm).then((res) => {
            sessionStorage.setItem("admintoken", res.data.token);
            sessionStorage.setItem("realname", res.data.realname);
            sessionStorage.setItem("indexpath", "homePage");
            this.$message.success("登錄成功");
            this.$router.replace({
              path: "Home",
            });
          });
        }
      });
    },
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  padding: 50px 20px;
  position: relative;
  box-sizing: border-box;
  background-image: url(~@/assets/image/bg-login.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.login-form {
  position: relative;
  z-index: 2;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 50px 30px;
  width: 450px;
  max-width: 100%;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  background-color: #fff;
}
.login-form h3 {
  margin: 0 0 25px 0;
  text-align: center;
}

.login {
  width: 100%;
  min-height: 100%;
  background-size: 100%;
  display: flex;
}

#echarts {
  width: 600px;
  height: 400px;
}

.card {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin: auto;
}

.card-box {
  width: 368px;
}

.headers {
  display: flex;
  justify-content: space-evenly;

  span {
    padding: 10px 5px;
  }
}

.checkbox {
  padding: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: #1890ff;
    font-size: 14px;
  }
}

.button {
  padding: 20px 0;
}

.login-dlfs {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 14px;
    color: #606266;
  }

  .login-logo {
    margin-right: 80px;

    a {
      width: 24px;
      height: 24px;
      padding: 0 5px;

      img {
        width: 24px;
        height: 24px;
      }
    }
  }

  p {
    font-size: 14px;
    color: #1890ff;
  }
}

.active {
  border-bottom: 2px solid #1890ff;
  color: #1890ff;
}

.el-form-item {
  margin-bottom: 0px;
}

.box {
  text-align: center;
  .el-input {
    padding: 20px 0;
  }
}

::v-deep.el-button--primary.is-plain {
  background-color: #1890ff;
  color: #fff;
  border: none;
}
</style>
